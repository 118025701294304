import type { ComponentProps } from 'react';
import React from 'react';
import Button from '@clearscore/shared.website-cta';

import styles from './cta-section.module.css';

type ButtonProps = ComponentProps<typeof Button>;

export const justification = {
    center: 'center',
    start: 'start',
    end: 'end',
    spaceBetween: 'space-between',
    spaceAround: 'space-around',
    spaceEvenly: 'space-evenly',
} as const;

type JustifyContent = (typeof justification)[keyof typeof justification];

export interface ICTASectionProps {
    ctas?: Array<ButtonProps>;
    justifyContent?: JustifyContent;
}

export const CTASection = ({ ctas, justifyContent = 'center' }: ICTASectionProps): React.ReactElement => (
    <div
        data-id="cta-section"
        className={styles.component}
        style={{ '--justify-content': justifyContent } as React.CSSProperties}
    >
        {ctas?.map((props, index) => (
            <Button theme={Button.themes.INHERIT} key={index} {...props} />
        ))}
    </div>
);

export default CTASection;
