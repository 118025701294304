export const THEMES = {
    DARK: 'DARK',
    LIGHT: 'LIGHT',
} as const;

export const BUTTON_THEMES = {
    ...THEMES,
    INHERIT: 'INHERIT',
} as const;

export const DONUT_DIMENSIONS = {
    mobile: {
        height: '276px',
        width: '276px',
    },
    tablet: {
        height: '300px',
        width: '300px',
    },
    desktop: {
        height: '300px',
        width: '300px',
    },
};

export const ARC_SIZE_MAP = {
    xsmall: {
        viewBox: '0 0 390 18',
        d: 'M0 16.7203C63.5386 5.71378 128.666 0 195 0C261.334 0 326.461 5.71378 390 16.7203V17.6842H0L0 16.7203Z',
        yOffset: 17,
    },
    small: {
        viewBox: '0 0 390 18',
        d: 'M0 16.7203C63.5386 5.71378 128.666 0 195 0C261.334 0 326.461 5.71378 390 16.7203V17.6842H0L0 16.7203Z',
        yOffset: 17,
    },
    medium: {
        viewBox: '0 0 768 19',
        d: 'M0 18.608H768C642.977 6.97137 514.712 0.923828 384 0.923828C253.287 0.923828 125.023 6.97137 0 18.608Z',
        yOffset: 18,
    },
    large: {
        viewBox: '0 0 768 19',
        d: 'M0 18.608H768C642.977 6.97137 514.712 0.923828 384 0.923828C253.287 0.923828 125.023 6.97137 0 18.608Z',
        yOffset: 18,
    },
    xlarge: {
        viewBox: '0 0 768 19',
        d: 'M0 18.608H768C642.977 6.97137 514.712 0.923828 384 0.923828C253.287 0.923828 125.023 6.97137 0 18.608Z',
        yOffset: 18,
    },
} as const;

export const BREAKPOINTS = ['xsmall', 'small', 'medium', 'large'] as const;
export type Breakpoint = (typeof BREAKPOINTS)[number];
