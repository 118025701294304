/* eslint-disable camelcase */
import React from 'react';
import type { ReactElement } from 'react';
import { H1, H3, BODY } from '@clearscore/ui.cms.typography';
import Stack from '@clearscore/ui.rainbow.stack';
import Benefits from '@clearscore/shared.website-benefits';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import TrustPilot from '@clearscore/shared.website-trustpilot';
import AppStoreLinks from '@clearscore/shared.website-app-logos';
import { useCreateProspect } from '@clearscore/shared.website-hooks';
import BottomlessHero from '@clearscore/shared.website-bottomless-hero';
import useWebsiteTracking from '@clearscore-group/lib.hooks.use-website-tracking';
import TwoPanelSection from '@clearscore/shared.website-two-panel-section';
import CreateProspectForm from '@clearscore/shared.website-create-prospect-form';
import ContentfulRichTextRenderer from '@clearscore/shared.website-contentful-rich-text-renderer';
import AnalyticsProvider from '@clearscore/shared.website-analytics-provider';

import styles from './two-panel-hero.module.css';
import { signupHref, EMAIL_SUBMITTED_EVENT_NAME } from './lib/constants';

export interface IImage {
    description: string;
    gatsbyImageData: IGatsbyImageData;
}

export interface IAppStoreLinks {
    isAppStore: boolean;
    appStoreUrl: string;
    appStoreLogo: IImage;
    isGooglePlay: boolean;
    googlePlayUrl: string;
    googlePlayLogo: IImage;
}

export interface ITrustpilot {
    reviewUrl: string;
    templateId: string;
    businessUnitId: string;
    theme: (typeof TrustPilot.themes)[keyof typeof TrustPilot.themes];
}

export interface IBottomlessHero {
    heroImage: IImage;
    trustpilot?: ITrustpilot;
    appStoreLinks?: IAppStoreLinks;
    benefits: Array<{
        icon: {
            iconName: string;
            version?: string;
        };
        text: { raw: string };
        contentful_id: string;
        sys: {
            revision: number;
        };
    }>;
    text: { raw: string };
    contentful_id: string;
    sys: {
        revision: number;
    };
}

export interface IProspectForm {
    submitCta: string;
    isLightBg: boolean;
    isVertical: boolean;
    placeholder?: string;
    isValidError?: string;
    theme: (typeof CreateProspectForm.themes)[keyof typeof CreateProspectForm.themes];
}

export interface ITwoPanelHeroProps {
    heading: string;
    subHeading: string;
    cta?: IProspectForm;
    disclaimer: string;
    childrenBottom?: IBottomlessHero;
    imageMobile?: IImage;
    imageTablet?: IImage;
    imageDesktop?: IImage;
    backgroundDarkness?: number;
    bgImage?: {
        image: {
            gatsbyImageData: IGatsbyImageData;
            description?: string;
        };
        imageDesktop: {
            gatsbyImageData: IGatsbyImageData;
            description?: string;
        };
        imageDarknessPercentage?: number;
    };
    hasHeaderOverlay?: boolean;
}

export const TwoPanelHero = ({
    cta,
    heading,
    subHeading,
    disclaimer,
    childrenBottom,
    imageMobile,
    imageTablet,
    imageDesktop,
    backgroundDarkness,
    bgImage,
    hasHeaderOverlay = true,
}: ITwoPanelHeroProps): ReactElement => {
    const track = useWebsiteTracking();
    const { isLoading, handleSubmit } = useCreateProspect();

    return (
        <TwoPanelSection
            backgroundDarkness={backgroundDarkness}
            childrenTop={
                <Stack all={Stack.spacings.SUPER} large={Stack.spacings.HUGE}>
                    <Stack all={Stack.spacings.LARGE}>
                        <H1>{heading}</H1>
                        <H3 tag="h2">{subHeading}</H3>
                    </Stack>
                    <Stack all={Stack.spacings.BIG} large={Stack.spacings.LARGE}>
                        {cta ? (
                            <div className={styles.createProspectForm}>
                                <CreateProspectForm
                                    theme={cta.theme}
                                    language={{
                                        submitCta: cta.submitCta,
                                        placeholder: cta.placeholder,
                                        isValidError: cta.isValidError,
                                    }}
                                    isLightBg={cta.isLightBg}
                                    isLoading={isLoading}
                                    onSubmit={handleSubmit}
                                    onClickSubmit={(isValid) =>
                                        track({
                                            name: EMAIL_SUBMITTED_EVENT_NAME,
                                            props: {
                                                e: EMAIL_SUBMITTED_EVENT_NAME,
                                                cta_url: signupHref,
                                                cta_copy: cta.submitCta,
                                                module_type: 'Hero',
                                                section_body_copy: subHeading,
                                                section_header_copy: heading,
                                                email_submit_successful: isValid.toString(),
                                            },
                                        })
                                    }
                                />
                            </div>
                        ) : null}
                        <BODY>{disclaimer}</BODY>
                    </Stack>
                </Stack>
            }
            childrenBottom={
                childrenBottom ? (
                    <BottomlessHero
                        imageAlt={childrenBottom.heroImage.description}
                        image={childrenBottom.heroImage.gatsbyImageData}
                    >
                        <Stack all={Stack.spacings.BIG}>
                            {childrenBottom.trustpilot ? (
                                <div className={styles.trustPilotContainer}>
                                    <TrustPilot theme={childrenBottom.trustpilot.theme} />
                                </div>
                            ) : null}
                            {childrenBottom.appStoreLinks ? (
                                <div className={styles.appLinksContainer}>
                                    <AppStoreLinks
                                        panel={{ type: 'hero' }}
                                        appStoreConfig={{
                                            appStoreUrl: childrenBottom.appStoreLinks.appStoreUrl,
                                            appleStoreToggle: childrenBottom.appStoreLinks.isAppStore,
                                            googlePlayUrl: childrenBottom.appStoreLinks.googlePlayUrl,
                                            googleStoreToggle: childrenBottom.appStoreLinks.isGooglePlay,
                                        }}
                                    />
                                </div>
                            ) : null}
                            <Benefits benefits={childrenBottom.benefits} />
                            <AnalyticsProvider
                                trackingData={{
                                    entry_id: childrenBottom.contentful_id,
                                    entry_version: childrenBottom.sys.revision,
                                }}
                            >
                                <ContentfulRichTextRenderer text={childrenBottom.text} />
                            </AnalyticsProvider>
                        </Stack>
                    </BottomlessHero>
                ) : undefined
            }
            images={
                imageDesktop && imageTablet && imageMobile
                    ? {
                          backgroundDesktop: { image: imageDesktop.gatsbyImageData, alt: imageDesktop.description },
                          backgroundTablet: { image: imageTablet.gatsbyImageData, alt: imageTablet.description },
                          backgroundMobile: { image: imageMobile.gatsbyImageData, alt: imageMobile.description },
                      }
                    : undefined
            }
            bgImage={bgImage}
            hasHeaderOverlay={hasHeaderOverlay}
        />
    );
};

export default TwoPanelHero;
