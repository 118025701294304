import type { ReactElement } from 'react';
import React from 'react';
import cx from 'classnames';
import CreateProspectForm from '@clearscore/shared.website-create-prospect-form';
import Section from '@clearscore/shared.website-section';
import type { ISection } from '@clearscore/shared.website-section';
import { GatsbyImage } from 'gatsby-plugin-image';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import Stack from '@clearscore/ui.rainbow.stack';
import Text from '@clearscore/ui.rainbow.text';
import { useCreateProspect } from '@clearscore/shared.website-hooks';
import type Button from '@clearscore/ui.rainbow.button';
import { mediaQuery } from '@clearscore/ui.rainbow.tokens';
import useMedia from '@clearscore-group/lib.hooks.use-media';
import Container from '@clearscore/ui.rainbow.container';

import { DONUT_DIMENSIONS, ARC_SIZE_MAP } from './lib/constants';
import Donut from './components/donut';
import type { BUTTON_THEMES, Breakpoint } from './lib/constants';
import styles from './donut-landing-hero.module.css';

export interface IProspectForm {
    submitCta: string;
    isLightBg: boolean;
    isVertical: boolean;
    placeholder?: string;
    isValidError?: string;
    theme: (typeof CreateProspectForm.themes)[keyof typeof CreateProspectForm.themes];
}

export interface IHighlight {
    highlightText: string;
    highlightIcon: {
        gatsbyImageData: IGatsbyImageData;
        description?: string;
    };
}

type TContainerSpacing = typeof Container.Spacing;
type TSectionSpacing = TContainerSpacing[keyof TContainerSpacing] | 'DYNAMIC';

export interface IDonutLandingHeroProps {
    heading: string;
    subHeading?: string;
    theme?: 'light' | 'dark';
    prospectFormButtonTheme?: (typeof BUTTON_THEMES)[keyof typeof BUTTON_THEMES];
    prospectFormButtonType?: (typeof Button.types)[keyof typeof Button.types];
    prospectForm?: IProspectForm;
    backgroundColor?: (typeof Section.BackgroundColor)[keyof typeof Section.BackgroundColor];
    gradientColor?: (typeof Section.BackgroundColor)[keyof typeof Section.BackgroundColor];
    animatedDonut?: boolean;
    compactHeight?: boolean;
    highlights?: Array<IHighlight>;
    image: {
        gatsbyImageData: IGatsbyImageData;
        description?: string;
    };
    backgroundImage?: {
        image: {
            gatsbyImageData: IGatsbyImageData;
            description?: string;
        };
        imageDesktop: {
            gatsbyImageData: IGatsbyImageData;
            description?: string;
        };
        imageDarknessPercentage?: number;
    };
    width?: ISection['size'];
    spacingTop?: TSectionSpacing;
    spacingBottom?: TSectionSpacing;
    bottomArc?: boolean;
    arcBackgroundColor?: (typeof Section.BackgroundColor)[keyof typeof Section.BackgroundColor];
}

const DonutLandingHero = ({
    heading,
    subHeading,
    theme,
    prospectFormButtonTheme,
    prospectFormButtonType,
    width,
    spacingBottom,
    spacingTop,
    prospectForm,
    backgroundColor,
    gradientColor,
    animatedDonut,
    compactHeight,
    image,
    backgroundImage,
    highlights,
    bottomArc = false,
    arcBackgroundColor,
}: IDonutLandingHeroProps): ReactElement => {
    const { isLoading, handleSubmit } = useCreateProspect();
    const isXSmall = useMedia([null, mediaQuery['rnb-mq-small']], [true, false]);
    const isMobile = useMedia([null, mediaQuery['rnb-mq-medium']], [true, false]);
    const isTablet = useMedia([null, mediaQuery['rnb-mq-large']], [true, false]);
    const donutTabletDimension = isTablet ? DONUT_DIMENSIONS.tablet : DONUT_DIMENSIONS.desktop;
    const donutDimensions = isMobile ? DONUT_DIMENSIONS.mobile : donutTabletDimension;

    const breakpoint = () => {
        if (isXSmall) return 'xsmall';
        if (isMobile) return 'small';
        if (isTablet) return 'medium';
        return 'large';
    };

    return (
        <div className={styles.donutSectionContainer}>
            <Section
                theme={theme}
                backgroundColor={backgroundColor}
                size={width}
                spacingTop={spacingTop}
                spacingBottom={spacingBottom}
                id="donut-landing-hero"
                dataId={`donut-landing-hero-${gradientColor || 'transparent'}`}
                backgroundImage={
                    backgroundImage ? (
                        <Section.BackgroundImage
                            image={backgroundImage.image}
                            imageDesktop={backgroundImage.imageDesktop}
                        />
                    ) : undefined
                }
                imageDarknessPercentage={backgroundImage?.imageDarknessPercentage}
            >
                <div className={styles.donutHeroContainer}>
                    <div
                        className={cx(styles.donutHero, {
                            [styles.compactHeight]: compactHeight,
                            [styles.hasBottomArc]: bottomArc,
                        })}
                    >
                        <div className={styles.mobileOnlyHeading}>
                            <Text tag={Text.tags.P}>{heading}</Text>
                        </div>

                        <div className={cx(styles.contentWrapper, { [styles.hasArc]: bottomArc })}>
                            <Stack all="medium">
                                <div className={styles.heading}>
                                    <Text tag={Text.tags.H1}>{heading}</Text>
                                </div>
                                {subHeading ? (
                                    <div className={styles.subHeading}>
                                        <Text tag={Text.tags.P}>{subHeading}</Text>
                                    </div>
                                ) : null}
                            </Stack>
                            <Stack all="medium">
                                {prospectForm ? (
                                    <div className={styles.prospectForm}>
                                        <CreateProspectForm
                                            isHorizontal
                                            theme={prospectForm.theme}
                                            language={{
                                                submitCta: prospectForm.submitCta,
                                                placeholder: prospectForm.placeholder,
                                                isValidError: prospectForm.isValidError,
                                            }}
                                            isLightBg={prospectForm.isLightBg}
                                            isLoading={isLoading}
                                            onSubmit={handleSubmit}
                                            buttonTheme={prospectFormButtonTheme}
                                            buttonType={prospectFormButtonType}
                                        />
                                    </div>
                                ) : null}
                            </Stack>
                        </div>

                        <div
                            className={cx(styles.imageWrapper, {
                                [styles.centered]: animatedDonut,
                                [styles.hasArc]: bottomArc,
                            })}
                        >
                            {animatedDonut ? (
                                <div
                                    className={styles.donut}
                                    style={{
                                        backdropFilter: 'blur(10px)',
                                        transform: 'translateY(0)',
                                        ...donutDimensions,
                                    }}
                                    data-id="donut"
                                >
                                    <Donut donutDimensions={donutDimensions} isMobile={isMobile} />
                                </div>
                            ) : (
                                <GatsbyImage
                                    alt={image.description ?? ''}
                                    image={image.gatsbyImageData}
                                    className={styles.image}
                                    objectFit="contain"
                                />
                            )}
                        </div>
                    </div>

                    {!bottomArc ? (
                        <ul className={styles.highlights}>
                            {highlights &&
                                highlights.map((highlight, index) => (
                                    <li key={index} className={styles.highlight}>
                                        <GatsbyImage
                                            alt={highlight.highlightIcon.description ?? ''}
                                            image={highlight.highlightIcon.gatsbyImageData}
                                            className={styles.highlightIcon}
                                            objectFit="contain"
                                        />
                                        <Text tag={Text.tags.P}>{highlight.highlightText}</Text>
                                    </li>
                                ))}
                        </ul>
                    ) : null}
                </div>
            </Section>

            {bottomArc ? (
                <div
                    className={styles.bottomArcContainer}
                    style={
                        arcBackgroundColor
                            ? ({ backgroundColor: `var(--arc-bg-${arcBackgroundColor})` } as React.CSSProperties)
                            : undefined
                    }
                >
                    <svg
                        className={styles.bottomArc}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={ARC_SIZE_MAP[breakpoint() as Breakpoint]?.viewBox}
                        preserveAspectRatio="none"
                    >
                        <path
                            className={styles.arc}
                            style={
                                arcBackgroundColor
                                    ? ({ fill: `var(--arc-bg-${arcBackgroundColor})` } as React.CSSProperties)
                                    : undefined
                            }
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d={ARC_SIZE_MAP[breakpoint() as Breakpoint]?.d}
                        />
                    </svg>
                    <Container size={width}>
                        <ul className={cx(styles.highlights, { [styles.hasArc]: bottomArc })}>
                            {highlights &&
                                highlights.map((highlight, index) => (
                                    <li key={index} className={styles.highlight}>
                                        <GatsbyImage
                                            alt={highlight.highlightIcon.description ?? ''}
                                            image={highlight.highlightIcon.gatsbyImageData}
                                            className={styles.highlightIcon}
                                            objectFit="contain"
                                        />
                                        <Text tag={Text.tags.P}>{highlight.highlightText}</Text>
                                    </li>
                                ))}
                        </ul>
                    </Container>
                </div>
            ) : null}
        </div>
    );
};

export default DonutLandingHero;
