import type { ComponentProps, ReactElement } from 'react';
import cx from 'classnames';
import React, { useState, useLayoutEffect, useRef } from 'react';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import type { ICTAProps } from '@clearscore/shared.website-cta';
import { GatsbyImage } from 'gatsby-plugin-image';
import ContentfulRichTextRenderer from '@clearscore/shared.website-contentful-rich-text-renderer';
import CTA from '@clearscore/shared.website-cta';
import CardWebsiteContent from '@clearscore/shared.card-website-content';

import type { ContainerColor } from './lib/constants';
import styles from './text-and-image-grid.module.css';

interface IContentfulImage {
    gatsbyImageData: IGatsbyImageData;
    description: string;
}

export interface ITextAndImageChildProps {
    text: {
        raw: string;
    };
    image: IContentfulImage;
    cta?: ICTAProps[];
    containerColor?: (typeof ContainerColor)[keyof typeof ContainerColor];
    animateOnScroll?: boolean;
    imagePosition?: 'top' | 'left' | 'bottom' | 'right';
}

export const componentMap = {
    ContentCard: (props: ComponentProps<typeof CardWebsiteContent>) => (
        <div className={styles.cardContainer}>
            <CardWebsiteContent {...props} />
        </div>
    ),
};

export interface ITextAndImageGridProps {
    textAndImageChildren: Array<ITextAndImageChildProps>;
}

const TextAndImageChild = ({
    text,
    image,
    cta,
    containerColor,
    animateOnScroll,
    imagePosition = 'bottom',
}: ITextAndImageChildProps): ReactElement => {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsIntersecting(entry.isIntersecting);
            },
            { rootMargin: '0px', threshold: 0.4 },
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isIntersecting]);

    return (
        <div
            className={cx(styles.textAndImageChild, {
                [styles[`${imagePosition}Image`]]: imagePosition,
                [styles.containerized]: containerColor,
            })}
            data-id="text-and-image-child-component"
            ref={ref}
            style={
                {
                    backgroundColor: containerColor ? `var(--website-container-${containerColor})` : 'transparent',
                } as React.CSSProperties
            }
        >
            <div className={styles.text}>
                <ContentfulRichTextRenderer text={text} />

                {cta ? (
                    <div
                        className={cx(styles.cta, {
                            [styles.light]: cta[0]?.theme === 'LIGHT',
                        })}
                    >
                        {cta.map((ctaProps) => (
                            <CTA {...ctaProps} key={ctaProps.text} />
                        ))}
                    </div>
                ) : null}
            </div>

            {animateOnScroll ? (
                <GatsbyImage
                    style={{
                        opacity: isIntersecting ? '1' : '0',
                    }}
                    className={styles.image}
                    image={image.gatsbyImageData}
                    alt={image.description ?? ''}
                    objectPosition="top"
                />
            ) : (
                <GatsbyImage
                    className={styles.image}
                    image={image.gatsbyImageData}
                    alt={image.description ?? ''}
                    objectPosition="top"
                />
            )}
        </div>
    );
};

export const TextAndImageGrid = ({ textAndImageChildren }: ITextAndImageGridProps): ReactElement => (
    <div className={styles.textAndImageGrid} data-id="text-and-image-grid-component">
        {textAndImageChildren
            ? textAndImageChildren.map((element, index) => <TextAndImageChild {...element} key={index} />)
            : null}
    </div>
);

export default TextAndImageGrid;
