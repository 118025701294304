import React from 'react';
import ContentfulRichText from '@clearscore/shared.website-contentful-rich-text-renderer';
import type { IContentfulRichTextRendererProps } from '@clearscore/shared.website-contentful-rich-text-renderer';
import type { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { GatsbyImage } from 'gatsby-plugin-image';

export interface IRichTextProps {
    text: IContentfulRichTextRendererProps['text'];
}

const renderOverrides: Options = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => (
            <GatsbyImage image={node.data.target.gatsbyImageData} alt={node.data.target.title} />
        ),
    },
};

const RichText = ({ text }: IRichTextProps): React.ReactElement => (
    <ContentfulRichText renderOverrides={renderOverrides} text={text} />
);

export default RichText;
