import useWebsiteTracking from '@clearscore-group/lib.hooks.use-website-tracking';
import type { IAnalyticsContextData } from '@clearscore/shared.website-analytics-provider';

const CLICK_EVENT_NAME = 'fe_cms_click';

export const useClickTracking = () => {
    const track = useWebsiteTracking();

    return (data: IAnalyticsContextData) => {
        track({
            name: CLICK_EVENT_NAME,
            props: data,
        });
    };
};
