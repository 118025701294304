import type { CSSProperties, ReactElement, ReactNode } from 'react';
import React from 'react';
import cx from 'classnames';
import ClearScoreIcon from '@clearscore/rainbow.icons.clearscore';
import TrendDownIcon from '@clearscore/rainbow.icons.trend-down';
import TrendUpIcon from '@clearscore/rainbow.icons.trend-up';

import styles from './tag.module.css';

// eslint-disable-next-line @typescript-eslint/ban-types
type Icon = (props?: ReactNode) => ReactElement;

type Style = CSSProperties | undefined;

const Theme = {
    LIGHT: 'LIGHT',
    DARK: 'DARK',
} as const;

export type Theme = keyof typeof Theme;

const DisplayState = {
    EXCLUSIVE: 'EXCLUSIVE',
    NYE: 'NYE',
    NYE_AU: 'NYE_AU',
    PRIMARY: 'PRIMARY',
    SECONDARY: 'SECONDARY',
    ATTENTION: 'ATTENTION',
    POSITIVE: 'POSITIVE',
    NEGATIVE: 'NEGATIVE',
    ACTION: 'ACTION',
    INFORMATION: 'INFORMATION',
    OPEN_BANKING: 'OPEN_BANKING',
    NEW: 'NEW',
    DARKEN: 'DARKEN',
    LIGHTEN: 'LIGHTEN',
    OUTLINE: 'OUTLINE',
    TREND_UP: 'TREND_UP',
    TREND_DOWN: 'TREND_DOWN',
    TRANSPARENT: 'TRANSPARENT',
    SPECIAL: 'SPECIAL',
    CUSTOM: 'CUSTOM',
} as const;

export type DisplayState = keyof typeof DisplayState;

const TagIcon = ({ children: ChildIcon, ...props }: { children: Icon } & any): ReactElement | null =>
    ChildIcon ? (
        <span className={styles.icon}>
            <ChildIcon {...props} />
        </span>
    ) : null;

type TagContainer = (props: {
    children: ReactElement | ReactElement[] | string;
    displayState: DisplayState;
    theme: Theme;
    dataId?: string;
    isBold?: boolean;
    style?: Style;
}) => ReactElement;

const TagContainer: TagContainer = ({
    children,
    displayState,
    theme,
    dataId = undefined,
    isBold = false,
    style = undefined,
}): ReactElement => (
    <div
        style={style}
        className={cx(
            styles.tag,
            styles[`displayState_${displayState}`],
            styles[`theme_${theme}`],
            styles[isBold ? 'bold' : 'regular'],
        )}
        data-qa="tag"
        data-id={dataId}
    >
        {children}
    </div>
);

type TagContent = (props: { children: ReactElement | ReactElement[] | string }) => ReactElement;

const TagContent: TagContent = ({ children }): ReactElement => <div className={styles.tagContent}>{children}</div>;

type Exclusive = (props: { theme: Theme; dataId?: string; children?: string }) => ReactElement;
const Exclusive: Exclusive = ({ theme, dataId, children = 'Exclusive' }): ReactElement => (
    <TagContainer theme={theme} displayState={DisplayState.EXCLUSIVE} dataId={dataId}>
        <TagContent>
            <TagIcon>{ClearScoreIcon}</TagIcon>
            <span>{children}</span>
        </TagContent>
    </TagContainer>
);

type TrendUp = (props: { theme: Theme; dataId?: string; children?: string }) => ReactElement;
const TrendUp: TrendUp = ({ theme, dataId, children = 'Trend' }): ReactElement => (
    <TagContainer theme={theme} displayState={DisplayState.TREND_UP} dataId={dataId} isBold>
        <TagContent>
            <span>{children}</span>
            <TagIcon>{TrendUpIcon}</TagIcon>
        </TagContent>
    </TagContainer>
);

type TrendDown = (props: { theme: Theme; dataId?: string; children?: string }) => ReactElement;
const TrendDown: TrendDown = ({ theme, dataId, children = 'Trend' }): ReactElement => (
    <TagContainer theme={theme} displayState={DisplayState.TREND_DOWN} dataId={dataId} isBold>
        <TagContent>
            <span>{children}</span>
            <TagIcon>{TrendDownIcon}</TagIcon>
        </TagContent>
    </TagContainer>
);

type New = (props: { theme: Theme; dataId?: string; children?: string }) => ReactElement;
const New: New = ({ theme, dataId, children = 'New' }): ReactElement => (
    <TagContainer theme={theme} displayState={DisplayState.NEW} dataId={dataId} isBold>
        <TagContent>{children}</TagContent>
    </TagContainer>
);

type Lighten = (props: { dataId?: string; theme: Theme; children: string }) => ReactElement;
const Lighten: Lighten = ({ dataId, theme, children }): ReactElement => (
    <TagContainer theme={theme} displayState={DisplayState.LIGHTEN} dataId={dataId} isBold>
        <TagContent>{children}</TagContent>
    </TagContainer>
);
type Darken = (props: { dataId?: string; theme: Theme; children: string }) => ReactElement;
const Darken: Darken = ({ dataId, theme, children }): ReactElement => (
    <TagContainer theme={theme} displayState={DisplayState.DARKEN} dataId={dataId} isBold>
        <TagContent>{children}</TagContent>
    </TagContainer>
);

type Outline = (props: { theme: Theme; dataId?: string; children: string }) => ReactElement;
const Outline: Outline = ({ theme, dataId, children }): ReactElement => (
    <TagContainer theme={theme} displayState={DisplayState.OUTLINE} dataId={dataId} isBold>
        <TagContent>{children}</TagContent>
    </TagContainer>
);

type Transparent = (props: { theme: Theme; dataId?: string; children: string }) => ReactElement;
const Transparent: Transparent = ({ theme, dataId, children }): ReactElement => (
    <TagContainer theme={theme} displayState={DisplayState.TRANSPARENT} dataId={dataId} isBold>
        <TagContent>{children}</TagContent>
    </TagContainer>
);

type Custom = (props: { theme: Theme; dataId?: string; children: string; style?: Style; Icon?: Icon }) => ReactElement;
const Custom: Custom = ({ theme, dataId, children, style, Icon }): ReactElement => (
    <TagContainer style={{ ...style }} theme={theme} displayState={DisplayState.CUSTOM} dataId={dataId}>
        <TagContent>
            <TagIcon>{Icon}</TagIcon>
            <span>{children}</span>
        </TagContent>
    </TagContainer>
);

interface Variants {
    [key: string]: Exclusive | TrendUp | TrendDown | Lighten | Darken | New | Outline | Transparent | Custom | null;
}

const Variants: Variants = {
    [DisplayState.EXCLUSIVE]: Exclusive,
    [DisplayState.TREND_UP]: TrendUp,
    [DisplayState.TREND_DOWN]: TrendDown,
    [DisplayState.LIGHTEN]: Lighten,
    [DisplayState.DARKEN]: Darken,
    [DisplayState.NEW]: New,
    [DisplayState.OUTLINE]: Outline,
    [DisplayState.TRANSPARENT]: Transparent,
    [DisplayState.CUSTOM]: Custom,
};

interface TagProps {
    children: string;
    displayState?: DisplayState;
    theme?: Theme;
    dataId?: string;
    Icon?: Icon;
    style?: Style;
}

export interface TagExport {
    (props: TagProps): ReactElement;
    theme: typeof Theme;
    displayState: typeof DisplayState;
    Icon: typeof TagIcon;
    Container: typeof TagContainer;
    Content: typeof TagContent;
}

const Tag: TagExport = ({ displayState = DisplayState.PRIMARY, theme = Theme.LIGHT, ...props }): ReactElement => {
    // return opinionated 'DisplayState' variants to ensure we are strict with what props can be used where
    const Variant = Variants[displayState];
    if (Variant) {
        return <Variant theme={theme} {...props} />;
    }
    return (
        <TagContainer theme={theme} displayState={displayState} dataId={props.dataId}>
            <TagContent>
                <TagIcon>{props.Icon}</TagIcon>
                <span>{props.children}</span>
            </TagContent>
        </TagContainer>
    );
};

Tag.theme = Theme;
Tag.displayState = DisplayState;
Tag.Icon = TagIcon;
Tag.Container = TagContainer;
Tag.Content = TagContent;

export default Tag;
