import React from 'react';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import { H3, BODY } from '@clearscore/ui.cms.typography';
import CSSThemeProvider from '@clearscore/ui.rainbow.css-theme-provider';
import cx from 'classnames';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { EN_GB } from '@clearscore-group/lib.config.i18n';

import copyEnGb from './copy/components/download-app-cta/en-gb/copy.json';
import styles from './download-app-cta.module.css';

const COMPONENT_NAMESPACE = 'download-app-cta';

i18next.addResourceBundle(EN_GB, COMPONENT_NAMESPACE, copyEnGb);

export interface IImage {
    gatsbyImageData: IGatsbyImageData;
    description?: string;
}

export interface IDownloadAppCtaProps {
    qr: IImage;
    theme?: 'light' | 'dark';
    isLightBg?: boolean;
}

const DownloadAppCta = ({ qr, theme = 'dark', isLightBg = false }: IDownloadAppCtaProps): React.ReactElement => {
    const { t } = useTranslation(COMPONENT_NAMESPACE);

    const themeClass = styles[theme];
    const contentClass = cx(styles.content, themeClass, {
        [styles.isLightBg]: isLightBg,
    });

    return (
        <CSSThemeProvider theme={theme}>
            <div className={contentClass}>
                <div className={styles.image}>
                    <GatsbyImage image={qr.gatsbyImageData} alt={qr.description || ''} />
                </div>
                <div className={styles.text}>
                    <H3>{t('downloadApp')}</H3>
                    <BODY>{t('downloadAppDescription')}</BODY>
                </div>
            </div>
        </CSSThemeProvider>
    );
};

export default DownloadAppCta;
