/* eslint-disable camelcase */
import React from 'react';
import IconLoader from '@clearscore/ui.rainbow.icon-loader';
import type { ReactElement, ComponentProps } from 'react';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import CreateProspectForm from '@clearscore/shared.website-create-prospect-form';
import DownloadAppCta from '@clearscore/shared.website-download-app-cta';
import type { IDownloadAppCtaProps } from '@clearscore/shared.website-download-app-cta';
import Section from '@clearscore/shared.website-section';
import type { ISection } from '@clearscore/shared.website-section';
import Logo from '@clearscore/rainbow.icons.clearscore-logo-wordmark';
import Stack from '@clearscore/ui.rainbow.stack';
import Spacer from '@clearscore/ui.rainbow.spacer';
import { useCreateProspect } from '@clearscore/shared.website-hooks';
import Text from '@clearscore/ui.rainbow.text';
import CTA from '@clearscore/shared.website-cta';
import type { ICTAProps } from '@clearscore/shared.website-cta';
import AppStoreLinks from '@clearscore/shared.website-app-logos';
import { GatsbyImage } from 'gatsby-plugin-image';
import cx from 'classnames';

import styles from './fullscreen-hero.module.css';

export interface IProspectForm {
    submitCta: string;
    isLightBg: boolean;
    isVertical: boolean;
    placeholder?: string;
    isValidError?: string;
    theme: (typeof CreateProspectForm.themes)[keyof typeof CreateProspectForm.themes];
}

export interface IAppStoreLinks {
    isAppStore: boolean;
    appStoreUrl: string;
    appStoreLogo: { description: string; gatsbyImageData: IGatsbyImageData };
    isGooglePlay: boolean;
    googlePlayUrl: string;
    googlePlayLogo: {
        description: string;
        gatsbyImageData: IGatsbyImageData;
    };
}

export interface IFullscreenHeroProps {
    sectionIndex?: number;
    heading: string;
    subHeading?: string;
    disclaimer?: string;
    imageBelowDisclaimer?: {
        gatsbyImageData: IGatsbyImageData;
        description?: string;
    };
    image?: {
        gatsbyImageData: IGatsbyImageData;
        description?: string;
    };
    mobileLeftAlignedText?: boolean;
    stackedMobileView?: boolean;
    pullContentUp?: boolean;
    veryBoldHeader?: boolean;
    prospectForm?: IProspectForm;
    ctas?: ICTAProps[];
    hasLogo?: boolean;
    backgroundColor?: (typeof Section.BackgroundColor)[keyof typeof Section.BackgroundColor];
    width?: ISection['size'];
    theme?: 'light' | 'dark';
    backgroundImage?: {
        image: {
            gatsbyImageData: IGatsbyImageData;
            description?: string;
        };
        imageDesktop: {
            gatsbyImageData: IGatsbyImageData;
            description?: string;
        };
        imageDarknessPercentage?: number;
    };
    appStoreLinks?: IAppStoreLinks;
    downloadAppCta?: IDownloadAppCtaProps;
    lowerIndicatorIcon?: ComponentProps<typeof IconLoader>;
    flipHorizontal?: boolean;
    hideAppStoreLinksOnMobile?: boolean;
}

// TODO: move this to global contentful data
export const straplineCopy = "It's time to be clear";

const FullscreenHero = ({
    heading,
    subHeading,
    disclaimer,
    image,
    imageBelowDisclaimer,
    mobileLeftAlignedText,
    stackedMobileView,
    pullContentUp,
    veryBoldHeader,
    prospectForm,
    ctas,
    hasLogo,
    backgroundImage,
    backgroundColor,
    width,
    theme,
    appStoreLinks,
    sectionIndex,
    downloadAppCta,
    lowerIndicatorIcon,
    flipHorizontal,
    hideAppStoreLinksOnMobile,
}: IFullscreenHeroProps): ReactElement => {
    const { isLoading, handleSubmit } = useCreateProspect();
    const isFirstSection = sectionIndex === 0;

    return (
        <Section
            theme={theme}
            backgroundColor={backgroundColor}
            size={width}
            spacingTop="NONE"
            spacingBottom="NONE"
            backgroundImage={
                backgroundImage ? (
                    <Section.BackgroundImage
                        image={backgroundImage.image}
                        imageDesktop={backgroundImage.imageDesktop}
                        objectPosition={!image ? 'bottom' : undefined}
                    />
                ) : undefined
            }
            imageDarknessPercentage={backgroundImage?.imageDarknessPercentage}
        >
            <div
                className={cx(styles.component, {
                    [styles.isFirstSection]: isFirstSection,
                    [styles.mobileLeftAligned]: mobileLeftAlignedText,
                    [styles.stackedMobileView]: stackedMobileView,
                    [styles.flipHorizontal]: flipHorizontal,
                    [styles.noImage]: !image,
                })}
            >
                <div className={cx(styles.contentWrapper, { [styles.pullContentUp]: pullContentUp })}>
                    <Stack all="small" medium="medium" large="big">
                        {hasLogo ? (
                            <div
                                className={cx(styles.logoContainer, {
                                    [styles.mobileLeftAligned]: mobileLeftAlignedText,
                                })}
                            >
                                <Spacer all={{ bottom: Spacer.spacings.MICRO }}>
                                    <Text tag={Text.tags.P} weight={Text.weights.REGULAR}>
                                        {straplineCopy}
                                    </Text>
                                </Spacer>
                                <Logo className={styles.logo} data-id="logo" />
                            </div>
                        ) : null}
                        <Stack
                            all="medium"
                            className={cx({
                                [styles.flipHeading]: !image,
                            })}
                        >
                            <div className={cx(styles.heading, { [styles.veryBold]: veryBoldHeader })}>
                                <Text tag={Text.tags.H1} weight={Text.weights.REGULAR}>
                                    {heading}
                                </Text>
                            </div>
                            {subHeading ? (
                                <div className={styles.subHeading}>
                                    <Text tag={Text.tags.P} weight={Text.weights.BOLD}>
                                        {subHeading}
                                    </Text>
                                </div>
                            ) : null}
                        </Stack>
                        <Stack all="medium">
                            {prospectForm ? (
                                <div
                                    className={cx(styles.prospectForm, {
                                        [styles.isFirstSection]: isFirstSection,
                                        [styles.stackedMobileView]: stackedMobileView,
                                    })}
                                >
                                    <CreateProspectForm
                                        isHorizontal
                                        theme={prospectForm.theme}
                                        language={{
                                            submitCta: prospectForm.submitCta,
                                            placeholder: prospectForm.placeholder,
                                            isValidError: prospectForm.isValidError,
                                        }}
                                        isLightBg={prospectForm.isLightBg}
                                        isLoading={isLoading}
                                        onSubmit={handleSubmit}
                                        buttonTheme="INHERIT"
                                    />
                                </div>
                            ) : null}
                            {disclaimer ? <Text.Body2>{disclaimer}</Text.Body2> : null}

                            {imageBelowDisclaimer ? (
                                <div className={styles.imageBelowDisclaimer}>
                                    <GatsbyImage
                                        alt={imageBelowDisclaimer.description ?? ''}
                                        image={imageBelowDisclaimer.gatsbyImageData}
                                        className={styles.disclaimerImage}
                                        objectFit="contain"
                                    />
                                </div>
                            ) : null}
                        </Stack>
                        {ctas ? (
                            <div className={styles.ctas}>
                                {ctas.map((cta) => (
                                    <CTA {...cta} key={cta.text} />
                                ))}
                            </div>
                        ) : null}
                        {downloadAppCta ? (
                            <div className={styles.downloadAppCta}>
                                <DownloadAppCta {...downloadAppCta} />
                            </div>
                        ) : null}
                    </Stack>
                </div>

                {image ? (
                    <div
                        className={cx(styles.imageWrapper, {
                            [styles.isFirstSection]: isFirstSection,
                            [styles.mobileImageBelowForm]: stackedMobileView,
                            [styles.pullContentUp]: pullContentUp,
                        })}
                    >
                        <GatsbyImage
                            alt={image.description ?? ''}
                            image={image.gatsbyImageData}
                            className={styles.image}
                            objectFit="contain"
                        />
                    </div>
                ) : null}

                {lowerIndicatorIcon ? (
                    <div className={styles.lowerIndicatorIcon}>
                        <IconLoader {...lowerIndicatorIcon} height={32} width={32} />
                    </div>
                ) : null}

                {appStoreLinks ? (
                    <div
                        className={cx(styles.appLinksContainer, {
                            [styles.appLinksContainerPersistent]: !hideAppStoreLinksOnMobile,
                        })}
                    >
                        <AppStoreLinks
                            panel={{ type: 'hero' }}
                            appStoreConfig={{
                                appStoreUrl: appStoreLinks.appStoreUrl,
                                appleStoreToggle: appStoreLinks.isAppStore,
                                googlePlayUrl: appStoreLinks.googlePlayUrl,
                                googleStoreToggle: appStoreLinks.isGooglePlay,
                                appStoreImage:
                                    appStoreLinks.appStoreLogo.gatsbyImageData.images.fallback?.src.split(' ')[0],
                                googlePlayImage:
                                    appStoreLinks.googlePlayLogo.gatsbyImageData.images.fallback?.src.split(' ')[0],
                            }}
                        />
                    </div>
                ) : null}
            </div>
        </Section>
    );
};

export default FullscreenHero;
