import '../fragments';
import type { ComponentProps, ReactElement } from 'react';
import React from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import ContentfulPage from '@clearscore/website.contentful-page';

import Layout from '../components/layout';

type TContentfulPage = Queries.ContentfulWebsitePageQuery['contentfulPage'];

const structuredData = (data: TContentfulPage) => {
    const _data = data.seoMetadata?.structuredData ?? data.structuredData;
    try {
        const items = _data
            ?.map(({ internal }) => internal.content)
            .reduce((previous, current) => `${previous}${current},`, '')
            .slice(0, -1);
        return items ? JSON.parse(`[${items}]`) : null;
    } catch (error) {
        return null;
    }
};

const getRobots = (data: TContentfulPage['seoMetadata']) => {
    if (data?.noindex && data?.nofollow) return 'noindex, nofollow';
    if (data?.noindex) return 'noindex';
    if (data?.nofollow) return 'nofollow';
    return '';
};

const getTitle = (data: TContentfulPage) => data.seoMetadata?.title ?? data.title ?? 'ClearScore';

const getDescription = (data: TContentfulPage) =>
    data.seoMetadata?.description?.description ?? data.description?.description ?? 'ClearScore';

const getImage = (data: TContentfulPage) => data.seoMetadata?.image?.resize?.src ?? data.image?.resize?.src;

const ContentfulWebsitePage = (
    props: PageProps<Queries.ContentfulWebsitePageQuery, { market?: string }>,
): ReactElement => {
    const { data, location } = props;
    return (
        <Layout
            meta={{
                title: getTitle(data.contentfulPage),
                description: getDescription(data.contentfulPage),
                image: getImage(data.contentfulPage),
                robots: getRobots(data.contentfulPage.seoMetadata),
                url: location.href,
            }}
            location={location.pathname}
            schemaMarkup={structuredData(data.contentfulPage)}
            canonicalUrl={data.contentfulPage.seoMetadata?.canonical}
        >
            {({ commonModules }): ReactElement => (
                <ContentfulPage
                    appStoreConfig={commonModules.app_store}
                    cookiePolicyConfig={commonModules.cookie_policy}
                    // Gql generated types are using `string` for union types
                    // so we need to cast it to the correct type.
                    data={data as ComponentProps<typeof ContentfulPage>['data']}
                />
            )}
        </Layout>
    );
};

export const query = graphql`
    query ContentfulWebsitePage($pageID: String) {
        contentfulPage(id: { eq: $pageID }) {
            ... on ContentfulPage {
                ...ContentfulPageFragment
            }
        }
    }
`;

export default ContentfulWebsitePage;
