import React from 'react';
import useMedia from '@clearscore-group/lib.hooks.use-media';
import type { mediaQuery } from '@clearscore/ui.rainbow.tokens';
import cx from 'classnames';

import { presets } from './constants';
import type { Tags, Types, Weights } from './constants';
import styles from './cms-typography-logged-in.module.css';

export interface ICmsTypographyProps {
    type: readonly Types[] | Types;
    tag: Tags;
    breakpoints?: readonly ((typeof mediaQuery)[keyof typeof mediaQuery] | null)[];
    weight?: Weights;
    children: React.ReactNode;
}

const headings = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
const lists = ['ul', 'ol'];

const CmsTypography = ({
    type,
    children,
    tag,
    breakpoints = [],
    weight = 'regular',
}: ICmsTypographyProps): React.ReactElement => {
    const typeValue = useMedia(breakpoints, type);
    const typeClass = styles[typeValue];
    const weightClass = styles[weight];
    const Tag = tag as React.ElementType;

    return (
        <Tag
            className={cx(
                styles.component,
                typeClass,
                weightClass,
                headings.includes(tag) && styles.heading,
                lists.includes(tag) && styles.list,
            )}
        >
            {children}
        </Tag>
    );
};

interface ICmsTypographyPresetProps {
    children: React.ReactNode;
    tag?: Tags;
}

const createFromPreset =
    (preset: (typeof presets)[keyof typeof presets]) =>
    ({ children, tag = preset.tag }: ICmsTypographyPresetProps): React.ReactElement =>
        (
            <CmsTypography {...preset} tag={tag}>
                {children}
            </CmsTypography>
        );

export const H1 = createFromPreset(presets.h1);
export const H2 = createFromPreset(presets.h2);
export const H3 = createFromPreset(presets.h3);
export const H4 = createFromPreset(presets.h4);
export const H5 = createFromPreset(presets.h5);
export const BODY = createFromPreset(presets.p);
export const UNORDERED_LIST = createFromPreset(presets.ul);
export const ORDERED_LIST = createFromPreset(presets.ol);
export const LIST_ITEM = createFromPreset(presets.li);
