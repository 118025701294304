import type { ReactElement } from 'react';
import React from 'react';
import cx from 'classnames';

import useProgressTimings from './hooks/use-progress-timings';
import styles from './progress-ring.module.css';

interface ProgressRingProps {
    maxValue: number;
    oldScore: number;
    newScore: number;
    strokeWidth?: number;
    size?: number;
    changeColour?: string;
    ringColour: string;
    isResetting?: boolean;
    setIsResetting?: (arg?: boolean) => void;
    progressDuration?: number;
}
interface ProgressRing {
    (props: ProgressRingProps): ReactElement;
}

const ProgressRing: ProgressRing = ({
    maxValue,
    oldScore,
    newScore,
    ringColour,
    changeColour = '#ffffff',
    isResetting = false,
    setIsResetting,
    strokeWidth = 1.5,
    size = 100,
    progressDuration = 1000,
}) => {
    const { progress } = useProgressTimings({
        newScore,
        oldScore,
        maxValue,
        setIsResetting,
        isResetting,
        progressDuration,
    });

    const circleSize = size / 2;
    const radius = size / 2 - strokeWidth * 2;
    const circumference = 2 * Math.PI * radius;

    const strokeDashoffset = circumference - progress.ringOne * circumference;
    const strokeDashoffsetAdjustment = circumference - progress.ringTwo * circumference;

    return (
        <div className={styles.container}>
            <svg
                className={cx(styles.svg, { [styles.isResetting]: isResetting })}
                viewBox={`0 0 ${size} ${size}`}
                style={{
                    transition: `transform ${isResetting ? '1s' : '0s'} ease-in-out`,
                }}
                data-qa="progress-ring"
            >
                <circle
                    style={{
                        stroke: changeColour,
                    }}
                    className={styles.circle}
                    cx={circleSize}
                    cy={circleSize}
                    r={radius}
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffsetAdjustment}
                    strokeWidth={strokeWidth}
                    data-qa="progress-ring-change-colour"
                />
                <circle
                    style={{
                        stroke: ringColour,
                    }}
                    className={styles.circle}
                    cx={circleSize}
                    cy={circleSize}
                    r={radius}
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                    strokeWidth={strokeWidth}
                    data-qa="progress-ring-colour"
                />
            </svg>
        </div>
    );
};

export default ProgressRing;
