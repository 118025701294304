import { mediaQuery } from '@clearscore/ui.rainbow.tokens';

export type Tags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'ul' | 'ol' | 'li';
export type Types = 'mini' | 'small' | 'medium' | 'large' | 'big' | 'huge' | 'massive';
export type Weights = 'regular' | 'bold';

export const presets = {
    h1: {
        type: ['big', 'huge'],
        tag: 'h1',
        breakpoints: [null, mediaQuery['rnb-mq-medium']],
    },
    h2: {
        type: ['medium', 'large'],
        tag: 'h2',
        weight: 'bold',
        breakpoints: [null, mediaQuery['rnb-mq-medium']],
    },
    h3: {
        type: ['small'],
        tag: 'h3',
        weight: 'bold',
        breakpoints: [null, mediaQuery['rnb-mq-medium']],
    },
    h4: {
        type: 'mini',
        weight: 'bold',
        tag: 'h4',
    },
    h5: {
        type: 'mini',
        weight: 'bold',
        tag: 'h5',
    },
    p: {
        type: 'small',
        tag: 'p',
    },
    ul: {
        type: 'small',
        tag: 'ul',
    },
    ol: {
        type: 'small',
        tag: 'ol',
    },
    li: {
        type: 'small',
        tag: 'li',
    },
} as const;
