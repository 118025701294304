import type { ReactElement } from 'react';
import React, { Fragment, useEffect, useState } from 'react';
import cx from 'classnames';
import Text from '@clearscore/ui.rainbow.text';
import SvgLoader from '@clearscore/ui.rainbow.icon-loader';
import ProgressRing from '@clearscore/ui.rainbow.better-score-progress-ring';

import {
    MAX_SCORE,
    STROKE_WIDTH,
    PROGRESS_DURATION,
    DONUT_SET_SCORE,
    DONUT_SET_INCREASE,
    DONUT_DENOMINATOR,
    DONUT_SUBHEADING,
} from './lib/constants';
import styles from './donut.module.css';
import type { DonutProps } from './lib/types';

interface Donut {
    (props: DonutProps): ReactElement;
}

const Donut: Donut = ({ donutDimensions }) => {
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        setFadeIn(true);
    }, []);

    return (
        <Fragment>
            <div
                style={{
                    // eslint-disable-next-line max-len
                    background: `linear-gradient(315deg, rgba(10, 82, 105, 1) 0%, rgba(10, 82, 105, 0.3) 100%`,
                }}
                className={styles.component}
            >
                <div className={styles.donutContent}>
                    <Text className={styles.heading}>Credit Score</Text>

                    <div className={cx(styles.scoreData, { [styles.fadeIn]: fadeIn })}>
                        <Text className={styles.score}>{DONUT_SET_SCORE}</Text>
                        <div className={styles.denominatorContainer}>
                            <div className={styles.increaseContainer}>
                                <SvgLoader
                                    data-id="icon"
                                    url="https://cdn-fs.clearscore.com/icons/@clearscore/rainbow.icons.arrow-north-east/0.2.0/arrow-north-east.svg"
                                    height={20}
                                    width={20}
                                />
                                <Text className={styles.increase}>{DONUT_SET_INCREASE}</Text>
                            </div>

                            <Text className={styles.denominator}>{DONUT_DENOMINATOR}</Text>
                        </div>
                    </div>

                    <Text className={styles.subHeading}>{DONUT_SUBHEADING}</Text>
                </div>

                <div className={styles.progressRing} style={{ ...donutDimensions }} data-qa="progress-ring">
                    <ProgressRing
                        oldScore={689}
                        maxValue={MAX_SCORE}
                        strokeWidth={STROKE_WIDTH}
                        newScore={724}
                        changeColour="#2cdb7b"
                        ringColour="#eeeeee"
                        progressDuration={PROGRESS_DURATION}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default Donut;
