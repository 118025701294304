import React, { Fragment } from 'react';
import type { ReactElement } from 'react';
import TrustPilot from '@clearscore/shared.website-trustpilot';
import Text from '@clearscore/ui.rainbow.text';
import Stack from '@clearscore/ui.rainbow.stack';
import Carousel from '@clearscore/ui.rainbow.carousel-core';
import ContentfulRichTextRenderer from '@clearscore/shared.website-contentful-rich-text-renderer';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';

import styles from './testimonials.module.css';

export interface ITestimonial {
    customerName?: string;
    title: string;
    starRatingImage?: {
        gatsbyImageData: IGatsbyImageData;
        description?: string;
    };
    testimonialText: { raw: string };
}

export interface ITestimonialsProps {
    heading?: string;
    testimonials: Array<ITestimonial>;
    appStoreLogo?: {
        gatsbyImageData: IGatsbyImageData;
        description?: string;
    };
    googlePlayLogo?: {
        gatsbyImageData: IGatsbyImageData;
        description?: string;
    };
    showTrustpilotLogo?: boolean;
}

const Testimonial = ({ customerName, title, starRatingImage, testimonialText }: ITestimonial): ReactElement => (
    <div className={styles.testimonial}>
        <div className={styles.testimonialHeader}>
            <Text
                className={styles.testimonialTitle}
                tag={Text.tags.P}
                weight={Text.weights.BOLD}
                type={Text.types.MEDIUM}
            >
                {title}
            </Text>
            {starRatingImage ? (
                <GatsbyImage
                    alt={starRatingImage.description ?? ''}
                    image={starRatingImage.gatsbyImageData}
                    className={styles.starRatingImage}
                    objectFit="contain"
                />
            ) : null}
        </div>
        <div className={styles.testimonialBody}>
            <div className={styles.testimonialText}>
                <ContentfulRichTextRenderer text={testimonialText} />
            </div>
            <Text className={styles.customerName} tag={Text.tags.P} weight={Text.weights.BOLD} type={Text.types.MEDIUM}>
                {customerName}
            </Text>
        </div>
    </div>
);

export const Testimonials = ({
    heading,
    testimonials = [],
    appStoreLogo,
    googlePlayLogo,
    showTrustpilotLogo,
}: ITestimonialsProps): ReactElement => (
    <Fragment>
        {heading ? (
            <Text
                className={styles.testimonialsSectionHeading}
                tag={Text.tags.H2}
                weight={Text.weights.BOLD}
                type={Text.types.LARGE}
            >
                {heading}
            </Text>
        ) : null}

        <Stack all={Stack.spacings.MEDIUM}>
            <div className={styles.testimonialsCarousel}>
                <Carousel numberOfIndicators={testimonials.length} duration={0}>
                    <div className={styles.testimonials}>
                        <ul className={styles.items}>
                            {testimonials.map((item, index) => (
                                <Carousel.Item index={index} key={item.title}>
                                    {() => (
                                        <li>
                                            <Testimonial key={item.title} {...item} />
                                        </li>
                                    )}
                                </Carousel.Item>
                            ))}
                        </ul>
                    </div>
                    <div className={styles.dots}>
                        <Carousel.Indicators numberOfIndicators={testimonials.length} />
                    </div>
                </Carousel>
            </div>
        </Stack>

        {showTrustpilotLogo ? (
            <div className={styles.trustpilotLogoContainer}>
                <TrustPilot theme={TrustPilot.themes.LIGHT} />
            </div>
        ) : null}

        <div className={styles.appLogos}>
            {appStoreLogo ? (
                <GatsbyImage
                    alt={appStoreLogo.description ?? ''}
                    image={appStoreLogo.gatsbyImageData}
                    className={styles.appLogo}
                    objectFit="contain"
                />
            ) : null}
            {googlePlayLogo ? (
                <GatsbyImage
                    alt={googlePlayLogo.description ?? ''}
                    image={googlePlayLogo.gatsbyImageData}
                    className={styles.appLogo}
                    objectFit="contain"
                />
            ) : null}
        </div>
    </Fragment>
);

export default Testimonials;
