import React from 'react';
import type { ReactElement } from 'react';
import Text from '@clearscore/ui.rainbow.text';
import PadlockIcon from '@clearscore/rainbow.icons.padlock';
import CreateProspectForm from '@clearscore/shared.website-create-prospect-form';
import { useCreateProspect } from '@clearscore/shared.website-hooks';
import i18next from 'i18next';
import { EN_GB, EN_CA } from '@clearscore-group/lib.config.i18n';
import { Trans, useTranslation } from 'react-i18next';

import copyEnCa from './lib/en-ca/copy.json';
import copyEnGb from './lib/en-gb/copy.json';
import type { Theme } from './lib/constants';
import { COMPONENT_NAMESPACE } from './lib/constants';
import styles from './create-account-prospect-form.module.css';

i18next.addResourceBundle(EN_GB, COMPONENT_NAMESPACE, copyEnGb);
i18next.addResourceBundle(EN_CA, COMPONENT_NAMESPACE, copyEnCa);

export interface ICreateAccountProspectFormProps {
    formHeading?: string;
    securityMessage?: string;
    emailAddressFormLabel?: string;
    emailAddressFormPlaceholder?: string;
    submitButtonText?: string;
    theme?: (typeof Theme)[keyof typeof Theme];
}

export const CreateAccountProspectForm = ({
    formHeading,
    securityMessage,
    emailAddressFormLabel,
    emailAddressFormPlaceholder,
    submitButtonText,
    theme,
}: ICreateAccountProspectFormProps): ReactElement => {
    const { isLoading, handleSubmit } = useCreateProspect();
    const { t } = useTranslation(COMPONENT_NAMESPACE);

    return (
        <div className={styles.createAccountProspectFormContainer}>
            {formHeading ? (
                <div className={styles.formHeading}>
                    <Text.H2>{formHeading}</Text.H2>
                </div>
            ) : null}

            {securityMessage ? (
                <div className={styles.securityMessageWithIcon}>
                    <PadlockIcon className={styles.padlockIcon} />
                    <Text.Body1 className={styles.securityMessage}>{securityMessage}</Text.Body1>
                </div>
            ) : null}

            {emailAddressFormLabel ? (
                <Text.Body1 className={styles.emailAddressFormLabel}>{emailAddressFormLabel}</Text.Body1>
            ) : null}

            <div className={styles.createAccountProspectForm}>
                <CreateProspectForm
                    theme={theme}
                    isVertical
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                    language={{
                        placeholder: emailAddressFormPlaceholder || t('placeholderDefault'),
                        submitCta: submitButtonText || t('submitButtonDefault'),
                    }}
                />
            </div>

            <div className={styles.tocMessage}>
                <Trans
                    i18nKey="tocMessage"
                    ns={COMPONENT_NAMESPACE}
                    components={[
                        <Text.Link href={t('tocUrl')} isNewTab>
                            link-text-replaced-by-i18
                        </Text.Link>,
                        <Text.Link href={t('privacyPolicyUrl')} isNewTab>
                            link-text-replaced-by-i18
                        </Text.Link>,
                    ]}
                />
            </div>
        </div>
    );
};

export default CreateAccountProspectForm;
